import "./styles.scss"

import axios from "axios"
import React, { useRef, useState } from "react"
import { AnimatePresence, motion } from "framer-motion"
import html2canvas from "html2canvas"
import screenshot from "image-screenshot"

import Modal from "components/Modal"
import Loader from "components/Loader"
import AddToCart from "components/AddToCart"
import ConfigPanel from "./components/ConfigPanel"
import ConfigPreview from "./components/ConfigPreview"
import IndividualOrderModal from "./components/ConfigPanel/components/IndividualOrderModal"
import Checkbox from "components/Checkbox"

import priceFormat from "utils/priceFormat"
import { CUBIC_BEZIER } from "utils/constants"

import { useConfig } from "context/config"
import ChangeArrowsIcon from "assets/icons/config/ChangeArrowsIcon"
import ReactPlayer from "react-player"

const animation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: {
    type: "tween",
    ease: CUBIC_BEZIER,
    duration: 0.3,
  },
}

function percentageInc(total, per) {
  return Math.floor(total + (per / 100) * total)
}

const Content = () => {
  const ref = useRef()

  const {
    init_loading,
    current_wallpaper,
    sizes,
    scale,
    saturation,
    brightness,
    hue,
    position,
    free_stock,
    setScale,
    setSizes,
    setSaturation,
    setBrightness,
    setHue,
    final_price,
    rorate_horizontal,
    rorate_vertical,
    setCurrentWallpaper,
    video_modal,
    setVideoModal,
  } = useConfig()

  const [individual_order_modal, setIndividualOrderModal] = useState(false)
  const [download_loading, setDownloadLoading] = useState(false)
  const [shipping_express, setShippingExpress] = useState(false)

  const onReset = () => {
    setScale(100)
    setSaturation(0)
    setBrightness(0)
    setHue(0)
    setSizes([400, 300])
    setCurrentWallpaper(prev => ({
      ...prev,
      texture: prev?.textures?.[0],
    }))
  }

  const onGenerateSubmit = async download => {
    try {
      if (!!download) {
        setDownloadLoading(true)
      }

      const canvasScreenshot = await html2canvas(
        ref.current.instance.wrapperComponent,
        {
          useCORS: true,
        }
      )

      document.body.appendChild(canvasScreenshot)
      const canvasElement = document.getElementsByTagName("canvas")?.[0]
      canvasElement.style.opacity = "0"
      canvasElement.style.filter = `saturate(${100 + saturation}%) brightness(${
        ((brightness > 0 ? brightness + 170 : brightness + 150) / 300) *
          (200 - 30) +
        15
      }%) hue-rotate(${hue}deg)`

      const base64 = await screenshot(canvasElement)
      canvasElement.remove()

      const data = {
        data: {
          wallpaper: {
            id: current_wallpaper?.product?.databaseId,
            name: current_wallpaper?.product?.name,
            slug: current_wallpaper?.product?.slug,
            image: base64,
          },
          texture: {
            id: current_wallpaper?.texture?.databaseId,
            name: current_wallpaper?.texture?.title,
          },
          variant: {
            id: current_wallpaper?.variation?.id,
            label: current_wallpaper?.variation?.label,
            image: current_wallpaper?.variation?.image,
          },
          price: final_price,
        },
        transform: {
          scale: `${scale}%`,
          height: sizes?.[1],
          width: sizes?.[0],
          x: position?.[0],
          y: position?.[1],
          rorate_horizontal,
          rorate_vertical,
          saturation,
          brightness: brightness * 2,
          hue,
        },
        css: {
          saturation: 100 + saturation,
          brightness:
            ((brightness > 0 ? brightness + 170 : brightness + 150) / 300) *
              (200 - 30) +
            15,
        },
      }

      const generate = await axios.post(
        "https://cms.wallcraft.com.pl/wp-json/pdf-generator/v1/generate/",
        data
      )

      if (!!download) {
        window.open(generate?.data?.pdf_path, "_blank")
      } else {
        return generate?.data?.pdf_path
      }
    } catch (err) {
      console.error(err)
    }

    setDownloadLoading(false)
  }

  return (
    <section className="config-content">
      <AnimatePresence mode="wait">
        {!!init_loading ? (
          <motion.div
            key="loading"
            className="config-content__loader"
            {...animation}
          >
            <Loader color="primary" size="large" />
          </motion.div>
        ) : (
          <motion.div
            key="configurator"
            className="container-fluid"
            {...animation}
          >
            <div className="config-content__wrapper">
              <div className="config-content__left">
                <ConfigPreview ref={ref} final_price={final_price} />
              </div>
              <div className="config-content__right">
                <ConfigPanel onReset={onReset} />
              </div>
            </div>

            <div className="config-panel__options__option config-panel__options__option--reset">
              <span></span>
              <button onClick={onReset}>
                Przywróć domyślne ustawienia <ChangeArrowsIcon />
              </button>
            </div>

            <div className="config-content__wrapper config-content__wrapper--border">
              <div className="config-content__left">
                <div className="config-preview__items">
                  <div className="config-preview__item config-preview__item--price">
                    <h3>Cena skonfigurowanej tapety</h3>
                    <div className="config-preview__item__content">
                      <div className="config-preview__item__content__info">
                        <h3>
                          {shipping_express
                            ? priceFormat(percentageInc(final_price, 20))
                            : priceFormat(final_price)}{" "}
                          zł{" "}
                          <span>
                            /{" "}
                            {shipping_express
                              ? priceFormat(
                                  percentageInc(
                                    parseInt(
                                      current_wallpaper?.variation?.price
                                    ),
                                    20
                                  )
                                )
                              : priceFormat(
                                  current_wallpaper?.variation?.price
                                )}{" "}
                            zł/m
                            <sup>2</sup>
                          </span>
                        </h3>
                        {!!!download_loading ? (
                          <button onClick={() => onGenerateSubmit(true)}>
                            Pobierz aktualną konfigurację
                          </button>
                        ) : (
                          <div className="config-preview__item__content__info__loader">
                            <Loader color="primary" /> Trwa pobieranie
                          </div>
                        )}
                      </div>
                      <div>
                        <AddToCart
                          type="Wallpaper"
                          databaseId={current_wallpaper?.variation?.id}
                          product={{
                            ...current_wallpaper?.product,
                            price: final_price?.toString(),
                          }}
                          generatePdf={onGenerateSubmit}
                          extraData={{
                            texture:
                              current_wallpaper?.texture?.databaseId?.toString(),
                            custom_price: shipping_express
                              ? percentageInc(final_price, 20)
                              : final_price,
                            scale: parseFloat(scale)?.toFixed(0)?.toString(),
                            free_stock: !!free_stock ? "true" : "false",
                            width: sizes?.[0]?.toString(),
                            height: sizes?.[1]?.toString(),
                            x: position?.[0]?.toString(),
                            y: position?.[1]?.toString(),
                            processing_time: shipping_express
                              ? "express"
                              : "standard",
                            rotate_horizontal: rorate_horizontal?.toString(),
                            rotate_vertical: rorate_vertical?.toString(),
                            saturation: saturation?.toFixed(0)?.toString(),
                            brightness: parseFloat(brightness * 2)
                              ?.toFixed(0)
                              ?.toString(),
                            hue: hue?.toFixed(0)?.toString(),
                          }}
                          processingTime={
                            shipping_express ? "express" : "standard"
                          }
                        />

                        <div className="config-preview__express">
                          <Checkbox
                            name="legalFirst"
                            checked={shipping_express}
                            onChange={() =>
                              setShippingExpress(!shipping_express)
                            }
                            required
                          >
                            <strong>Ustaw ekspresowy czas realizacji</strong>
                            <br />
                            (3 dni robocze, +20% ceny produktu)
                          </Checkbox>
                        </div>
                      </div>
                    </div>
                  </div>

                  {!!current_wallpaper?.product?.samples?.[0]?.id && (
                    <div className="config-preview__item config-preview__item--sample">
                      <h3>Cena próbki wzoru</h3>
                      <div className="config-preview__item__content">
                        <div className="config-preview__item__content__info">
                          <h3>
                            {current_wallpaper?.product?.samples?.[0]?.price} zł
                          </h3>
                          <span>
                            Fragment tapety o wymiarach 30x60 cm, zgodny z
                            konfiguracją.
                          </span>
                        </div>
                        <AddToCart
                          type="Sample"
                          databaseId={
                            current_wallpaper?.product?.samples?.[0]?.id
                          }
                          product={{
                            ...current_wallpaper?.product?.samples?.[0],
                            price:
                              current_wallpaper?.product?.samples?.[0]?.price?.toString(),
                          }}
                          extraData={{
                            texture:
                              current_wallpaper?.texture?.databaseId?.toString(),
                            custom_price:
                              current_wallpaper?.product?.samples?.[0]?.price,
                            variation: current_wallpaper?.variation?.label,
                            scale: parseFloat(scale)?.toFixed(0)?.toString(),
                            width: "30",
                            height: "60",
                            x: position?.[0]?.toString(),
                            y: position?.[1]?.toString(),
                            processing_time: "standard",
                            rotate_horizontal: rorate_horizontal?.toString(),
                            rotate_vertical: rorate_vertical?.toString(),
                            saturation: saturation?.toFixed(0)?.toString(),
                            brightness: parseFloat(brightness * 2)
                              ?.toFixed(0)
                              ?.toString(),
                            hue: hue?.toFixed(0)?.toString(),
                          }}
                          button_variant="bordered"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="config-content__right">
                <div className="config-panel__info">
                  <strong>Potrzebujesz dodatkowej personalizacji?</strong>
                  <div className="config-panel__info__content">
                    <span>
                      Chcesz zmienić układ wzoru, kolorystykę poszczególnych
                      elementów lub po prostu potrzebujesz tapety o większych
                      wymiarach?
                    </span>
                    <button onClick={() => setIndividualOrderModal(true)}>
                      Napisz do nas
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {!!individual_order_modal && (
          <IndividualOrderModal
            onClose={() => setIndividualOrderModal(false)}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {!!video_modal && (
          <Modal
            header="Dowiedz się jak działa konfigurator"
            onClose={() => setVideoModal(false)}
          >
            <div className="home-project__video">
              <ReactPlayer
                url="https://vimeo.com/939032551"
                width="100%"
                height="100%"
                playing={false}
                controls={true}
                muted={false}
                playsinline={true}
                loop={false}
              />
            </div>
          </Modal>
        )}
      </AnimatePresence>
    </section>
  )
}

export default Content
