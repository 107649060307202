import React from "react"

const RotateHorizontalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <g id="Group_955" data-name="Group 955" transform="translate(-426 -408)">
      <g
        id="Ellipse_53"
        data-name="Ellipse 53"
        transform="translate(426 408)"
        fill="#fff"
        stroke="#ccc"
        strokeWidth="1"
      >
        <circle cx="32" cy="32" r="32" stroke="none" />
        <circle cx="32" cy="32" r="31.5" fill="none" />
      </g>
      <rect
        id="Rectangle_531"
        data-name="Rectangle 531"
        width="10"
        height="20"
        transform="translate(464 431)"
        fill="#ccc"
      />
      <rect
        id="Rectangle_532"
        data-name="Rectangle 532"
        width="10"
        height="20"
        transform="translate(442 431)"
        fill="#e6e6e6"
      />
      <line
        id="Line_73"
        data-name="Line 73"
        y1="33"
        transform="translate(458 424)"
        fill="none"
        stroke="#741c15"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default RotateHorizontalIcon
