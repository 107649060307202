import React from "react"

const RotateVerticalIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="64"
    height="64"
    viewBox="0 0 64 64"
  >
    <g id="Group_954" data-name="Group 954" transform="translate(-350 -408)">
      <g
        id="Ellipse_52"
        data-name="Ellipse 52"
        transform="translate(350 408)"
        fill="#fff"
        stroke="#ccc"
        strokeWidth="1"
      >
        <circle cx="32" cy="32" r="32" stroke="none" />
        <circle cx="32" cy="32" r="31.5" fill="none" />
      </g>
      <rect
        id="Rectangle_531"
        data-name="Rectangle 531"
        width="10"
        height="20"
        transform="translate(392 446) rotate(90)"
        fill="#ccc"
      />
      <rect
        id="Rectangle_532"
        data-name="Rectangle 532"
        width="10"
        height="20"
        transform="translate(392 424) rotate(90)"
        fill="#e6e6e6"
      />
      <line
        id="Line_73"
        data-name="Line 73"
        y1="33"
        transform="translate(399 440) rotate(90)"
        fill="none"
        stroke="#741c15"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
    </g>
  </svg>
)

export default RotateVerticalIcon
